// src/styles.js

import styled from "styled-components";

export const MachineContainer = styled.div`
  width: 300px;
  margin: 0 auto;
  text-align: center;
  font-family: "Twemoji", sans-serif;
`;

export const ReelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
`;

export const Reel = styled.div`
  width: 60px;
  height: 180px;
  background: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
`;

export const ReelInner = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Fruit = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
`;

export const SpinButton = styled.button`
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
`;
