// src/components/FruitMachine/FruitMachine.js

import React, { useState, useRef } from "react"; // Removed useEffect
import {
  SpinButton,
  Fruit,
  ReelInner,
  Reel,
  ReelContainer,
  MachineContainer,
} from "./styles";
import gsap from "gsap";
import {
  calculateWinnings,
  calculateWinProbability,
  calculateJackpotProbability,
} from "./scoring";

// Fruit Machine Component
const FruitMachine = () => {
  const fruits = ["🍎", "🍌", "🍒", "🍇", "🍉", "🍓", "🍍", "🥝", "🍑", "💎"];
  const [spinning, setSpinning] = useState(false);
  const [balance, setBalance] = useState(1.0); // Initial balance of £1
  const [moneyIn, setMoneyIn] = useState(0.0);
  const [moneyOut, setMoneyOut] = useState(0.0);
  const [machineFloat, setMachineFloat] = useState(100.0); // Initial float of £100
  const reelRefs = useRef([useRef(), useRef(), useRef()]);

  const getRandomFruits = () => {
    return new Array(10)
      .fill(null)
      .map(() => fruits[Math.floor(Math.random() * fruits.length)]);
  };

  const [reels, setReels] = useState([
    getRandomFruits(),
    getRandomFruits(),
    getRandomFruits(),
  ]);

  const spin = () => {
    if (balance < 0.01) {
      alert("Insufficient balance. Please add more money.");
      return;
    }

    setSpinning(true);
    setBalance((prevBalance) => prevBalance - 0.01);
    setMoneyIn((prevMoneyIn) => prevMoneyIn + 0.01);

    const elements = reelRefs.current.map((ref) => ref.current);

    elements.forEach((element, i) => {
      const spinDuration = Math.random() * (2 - 1) + 1; // Random duration between 1000ms and 2000ms
      const timeline = gsap.timeline();
      timeline.to(element, {
        y: "-=180px", // Spin upwards
        duration: 0.1, // Fast spin speed
        ease: "none",
        repeat: spinDuration * 10, // Repeat to cover the total duration
        onRepeat: () => {
          element.appendChild(element.firstChild);
          gsap.set(element, { y: 0 });
        },
      });

      setTimeout(() => {
        timeline.pause();
        const newFruits = getRandomFruits();
        setReels((prevReels) => {
          const newReels = [...prevReels];
          newReels[i] = newFruits;
          return newReels;
        });
        gsap.set(element, { y: 0 });
      }, spinDuration * 1000); // Total duration in milliseconds
    });

    setTimeout(() => {
      const { winnings, win } = calculateWinnings(reels);
      if (win) {
        setBalance((prevBalance) => prevBalance + winnings);
        setMoneyOut((prevMoneyOut) => prevMoneyOut + winnings);
      }
      setMachineFloat((prevFloat) => prevFloat + 0.01 - winnings);
      setSpinning(false);
    }, 2000); // Ensure all reels have finished spinning
  };

  const winProbability = calculateWinProbability(machineFloat);
  const jackpotProbability = calculateJackpotProbability(machineFloat);

  return (
    <MachineContainer>
      <h1>Fruit Machine</h1>
      <p>Money In: £{moneyIn.toFixed(2)}</p>
      <p>Money Out: £{moneyOut.toFixed(2)}</p>
      <p>Bank: £{balance.toFixed(2)}</p>
      <p>Chance of Next Spin Being a Winner: {winProbability}%</p>
      <p>Chance of Next Spin Hitting Jackpot: {jackpotProbability}%</p>
      <ReelContainer>
        {reels.map((reel, index) => (
          <Reel key={index}>
            <ReelInner ref={reelRefs.current[index]}>
              {reel.concat(reel.slice(0, 2)).map((fruit, i) => (
                <Fruit key={i}>{fruit}</Fruit>
              ))}
            </ReelInner>
          </Reel>
        ))}
      </ReelContainer>
      <SpinButton onClick={spin} disabled={spinning}>
        {spinning ? "Spinning..." : "Spin"}
      </SpinButton>
    </MachineContainer>
  );
};

export default FruitMachine;
