import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

export const firebaseApp = () => {
  const firebaseConfig = {
    apiKey: 'AIzaSyCmiD-mQJoUNvnON1Bn7yCgUzaeIZfSF4M',
    authDomain: 'widnesdevsite.firebaseapp.com',
    projectId: 'widnesdevsite',
    storageBucket: 'widnesdevsite.appspot.com',
    messagingSenderId: '628141525227',
    appId: '1:628141525227:web:f3252e13e95b22960696ae',
    measurementId: 'G-YR3W2CK4MP',
  };
  getAnalytics(initializeApp(firebaseConfig));
};
