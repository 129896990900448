import FruitMachine from "./components/FruitMachine/FruitMachine";

function App() {
  return (
    <div>
      <FruitMachine />
    </div>
  );
}

export default App;
